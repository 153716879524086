<template>
  <div class="my-integral">
    <el-breadcrumb>
      <el-breadcrumb-item :to="{ path: '/' }"><i class="el-icon-s-home"></i></el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/user' }">会员中心</el-breadcrumb-item>
      <el-breadcrumb-item>我的积分</el-breadcrumb-item>
    </el-breadcrumb>
    <div>
      <h3>我的积分</h3>
      <div class="intefral-list">
        <img src="../../assets/logo.png" alt="">
        <p>您现在还没有获得过积分！</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'myCollection'
}
</script>

<style scoped lang="scss">
@import "src/scss/index";
.my-integral {
  @include body;
  .intefral-list {
    @include flexCenter($direction: column, $justify: unset)
  }
}
</style>
